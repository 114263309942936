import { createActions, createReducer } from "reduxsauce";

const unAuthPercentage = 60;
const { Types, Creators } = createActions({
  requestProducts: [],
  successfulProducts: ["payload"],
  errorProducts: ["error"],
  requestCategories: [],
  successfulCategories: ["payload"],
  errorCategories: ["error"],
  successfulSetProduct: ["payload"],
  errorSetProduct: ["error"],
});

export const ProductsActionCreators = Creators;

const initialState = {
  loading: false,
  products: [],
  error: "",
  selectedProduct: {},
  searchText: "",
  message: "",
  categories: [],
};

const requestProducts = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  products: [],
});

const sortFunc = (a, b) => {
  const { catA } = a.categories && a.categories[0].name;
  const { catB } = b.categories[0].name;
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  let ar = [];
  map.forEach((value, key, map) => {
    ar = ar.concat(value);
  });
  return ar;
}

const successfulProducts = (state = initialState, action) => {
  const { message, products } = action.payload.result;
  const isAuthenticated = action.payload.isAuthenticated;
  if (!isAuthenticated) {
    return {
      ...state,
      loading: false,
      message,
      products: products
        .filter((prod) => prod.active)
        .map((prod) => {
          return {
            ...prod,
            prices:
              "" +
              (
                parseFloat(prod.prices) * ((100 + unAuthPercentage) / 100)
              ).toFixed(2),
            specialPrice: prod.specialPrice
              ? "" +
              (
                parseFloat(prod.specialPrice) * ((100 + unAuthPercentage) / 100)
              ).toFixed(2)
              : prod.specialPrice,
            colors:
              prod.colors?.length > 0
                ? prod.colors.map((col) => {
                  if (col.aggregatedPrice) {
                    return {
                      ...col,
                      aggregatedPrice:
                        "" +
                        (
                          parseFloat(col.aggregatedPrice) *
                          ((100 + unAuthPercentage) / 100)
                        ).toFixed(2),
                    };
                  }
                  return { ...col };
                })
                : [],
          };
        }),
    };
  }
  return {
    ...state,
    loading: false,
    message,
    // products: groupBy(products, pro => pro.categories && pro.categories[0].name),
    products: products.filter((prod) => prod.active),
  };
};

const errorProducts = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  products: null,
});

const successfulSetProduct = (state = initialState, action) => {
  const { product } = action.payload.result;
  const productSelected = product[0];
  const isAuthenticated = action.payload.isAuthenticated;
  console.log(isAuthenticated);
  const productWithUnAuthPrices = {
    ...productSelected, 
    prices:
      "" +
      (
        parseFloat(productSelected.prices) * ((100 + unAuthPercentage) / 100)
      ).toFixed(2),
    specialPrice: productSelected.specialPrice
      ? "" +
      (
        parseFloat(productSelected.specialPrice) * ((100 + unAuthPercentage) / 100)
      ).toFixed(2)
      : productSelected.specialPrice,
    colors:
      productSelected.colors?.length > 0
        ? productSelected.colors.map((col) => {
          if (col.aggregatedPrice) {
            return {
              ...col,
              aggregatedPrice:
                "" +
                (
                  parseFloat(col.aggregatedPrice) *
                  ((100 + unAuthPercentage) / 100)
                ).toFixed(2),
            };
          }
          return { ...col };
        })
        : [],
  }
  if(isAuthenticated){
    console.log("entro")
    console.log(product)
    return {
      ...state,
      selectedProduct: product,
      loading: false,
    };
  }
  return {
    ...state,
    selectedProduct: [productWithUnAuthPrices],
    loading: false,
  };
};

const requestCategories = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  categories: [],
});

const successfulCategories = (state = initialState, action) => {
  const { message, categories } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    categories,
  };
};

const errorCategories = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  categories: null,
});
const errorSetProduct = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

export default createReducer(initialState, {
  [Types.REQUEST_PRODUCTS]: requestProducts,
  [Types.SUCCESSFUL_PRODUCTS]: successfulProducts,
  [Types.ERROR_PRODUCTS]: errorProducts,
  [Types.REQUEST_CATEGORIES]: requestCategories,
  [Types.SUCCESSFUL_CATEGORIES]: successfulCategories,
  [Types.SUCCESSFUL_SET_PRODUCT]: successfulSetProduct,
  [Types.ERROR_CATEGORIES]: errorCategories,
  [Types.ERROR_SET_PRODUCT]: errorSetProduct
});
