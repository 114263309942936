  
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getProductsService = serviceBase({
	request: () => axiosClient.get('product/products/v2')
});

export const getCategoriesService = serviceBase({
	request: () => axiosClient.get('categories')
});

export const getProductService = serviceBase({
	request: (data) => axiosClient.get(`product/${data}`)
})

export default getProductsService;